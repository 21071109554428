<template>
    <div class="main_container">
        <NavBar/>
        <div class="tos_content">
            <div class="tos_title">
                TERMS OF SERVICE
            </div>
            <div class="tos_sub-content">
                <div class="tos_sub-item">
                    <span>COMPANY: </span>
                    REZI LEADS INC. and its affiliated entities (“Company”, “us”, “our”, and “we”)
                </div>
                <div class="tos_sub-item">
                    <span>COMPANY WEBSITE: </span>
                    The website located at rezileads.com (together with any websites on related domains or subdomains,
                    the “Site”).
                </div>
            </div>
            <div class="tos_title">
                AGREEMENT
            </div>


            <div class="tos_points">
                <p>These Terms of Use (these “Terms”) are a legal agreement between you and your business, if
                    applicable, on the one hand (together, “you” or “your”), and Company (as defined above) on the other
                    hand. These Terms govern your use of (i) any websites or web applications provided, published,
                    developed or made available by the Company, including the Site; (ii) any mobile or online
                    applications provided, published, licensed, developed or made available by the Company, including
                    the App; and (iii) any feature, content, software, hardware, services or other products available on
                    or through the Site or the App or otherwise provided by the Company (together with the Site and the
                    App, the “Services”). Information available through the Services are copyrighted works belonging to
                    the Company, as are the Site and the App. Certain Services may be subject to additional policies,
                    guidelines, terms, or rules, including the Company’s Privacy Policy, which will be posted on or in
                    connection with downloading or accessing the Site or App. All such additional terms, guidelines, and
                    rules are incorporated by reference into these Terms. These Terms shall not apply to Services that
                    post different terms of use.</p>
                <p>
                    THESE TERMS SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SERVICES.
                    BY ACCESSING OR USING THE SERVICES, YOU ARE AUTOMATICALLY ACCEPTING THESE TERMS (ON BEHALF OF
                    YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT,
                    AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                    REPRESENT). YOU MAY NOT ACCESS OR USE THE SERVICES OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18
                    YEARS OLD. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE
                    THE SERVICES.
                </p>
                <p>
                    THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
                    JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A
                    DISPUTE. SEE THE SECTION BELOW ENTITLED “DISPUTE RESOLUTION” FOR ADDITIONAL INFORMATION.
                </p>
                <ol>
                    <li>
                        <span>ACCOUNTS</span>
                        <ol>
                            <li>
                                <span>Account Creation.  </span> In order to use certain Services, you may be required
                                to register for an account on the Site or the App (“Account”) and provide certain
                                information about yourself as prompted by the account registration form. You represent
                                and warrant that: (a) all required registration information you submit is truthful and
                                accurate; and (b) you will maintain the accuracy of such information. You may delete
                                your Account at any time, for any reason, by following the instructions provided in your
                                Account. Company may suspend or terminate your Account in accordance with these Terms at
                                any time, and you agree that Company will not have any liability whatsoever to you for
                                any termination of your Account.
                            </li>
                            <li>
                                <span>Account Eligibility.  </span> As an express condition of being permitted to create
                                and access an Account, you represent and warrant that you (i) have the legal capacity
                                (including, without limitation, being of sufficient age) to enter into contracts under
                                the law of the jurisdiction in which you reside; (ii) are not on a list of persons
                                barred from receiving services under U.S. laws (including, without limitation, the
                                Denied Persons List and the Entity List issued by the U.S. Department of Commerce,
                                Bureau of Industry and Security) or other applicable jurisdictions; (iii) are not a
                                resident of any country which is subject to currently-ongoing sanctions imposed by the
                                United States of America or the United Nations; and (iv) are at least 13 years of age or
                                older, and if you are under the age of 18, your parent or legal guardian, as applicable,
                                has expressly consented to your use of the Services.
                            </li>
                            <li>
                                <span>Account Responsibilities. </span> You represent and warrant that all required
                                registration
                                information you submit is current, complete, truthful and accurate. You also agree and
                                acknowledge that you will maintain the accuracy and completeness of such information.
                                You are responsible for maintaining the confidentiality of your Account login
                                information and are fully responsible for all activities that occur under your Account.
                                You agree to immediately notify Company of any unauthorized use, or suspected
                                unauthorized use of your Account or any other breach of security. Company cannot and
                                will not be liable for any loss or damage arising from your failure to comply with the
                                above requirements. Furthermore, you accept all risks associated with the unauthorized
                                access to your Account.
                            </li>
                            <li>
                                <span>Account Password. </span> Upon registration for an Account, you will provide the
                                Company
                                with a password to access your Account. You are responsible for maintaining the
                                confidentiality of your password and for all of your activities and those of any third
                                party that occur through your Account, whether or not authorized by you. You agree to
                                immediately notify Company of any suspected or actual unauthorized use of your Account.
                                You agree that Company will not under any circumstances be liable for any cost, loss,
                                damages or expenses arising out of a failure by you to maintain the security of your
                                password or Account information.
                            </li>
                            <li>
                                <span>Account Notices. </span> By providing us with your email address, you agree to
                                receive all required notices electronically, to the email address provided. Notices will
                                be provided in HTML format, in the text of the e-mail delivered to you, in an electronic
                                document attached to the email, or through a link to an appropriate notice page on the
                                Site accessible through any standard, commercially available Internet browser.
                            </li>
                            <li>
                                <span>Account Termination. </span> You may delete your Account at any time, for any
                                reason, by
                                following the instructions provided in your Account. Company may suspend or terminate
                                your Account in accordance with these Terms at any time, and you agree that Company will
                                not have any liability whatsoever to you for any termination of your Account.
                            </li>
                            <li>
                                <span>Monitoring; Enforcement. </span> You hereby authorize Company, and Company hereby
                                reserves
                                the right (but has no obligation) to review any User Content submitted by you (if
                                applicable), to monitor your use of the Services and Company Content, and to investigate
                                and/or take appropriate action against you in our sole discretion if you violate the
                                Acceptable Use Policy or any other provision of these Terms or applicable laws, or
                                otherwise create liability for us or any other person. Such action may include removing
                                or modifying your User Content, terminating your Account in accordance with these Terms,
                                and/or reporting you to law enforcement authorities.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>ACCESS TO THE SERVICES</span>
                        <ol>
                            <li>
                                <span>License.  </span> Company grants you a non-transferable, non-exclusive, revocable,
                                non-sublicenseable, limited license to use and access the Services solely for your own
                                personal, noncommercial use, and to display Company Content (as defined herein) on your
                                computer or other device (the “License”), which License is expressly conditioned on
                                compliance with these Terms and is specifically subject to any restrictions set forth
                                herein. This License is revocable by Company at any time without notice or warning, and
                                the Company will not be liable to you or to any third party for any such revocation.
                                Unless explicitly stated herein, nothing in the Terms may be construed as conferring any
                                license to any intellectual property rights, whether by estoppel, implication or
                                otherwise.
                            </li>
                            <li>
                                <span>Certain Restrictions. </span> The License granted to you in these Terms are
                                subject to the following restrictions: (a) you shall not license, sell, rent, publish,
                                provide, lease, transfer, assign, distribute, host, or otherwise commercially exploit
                                the Services, whether in whole or in part, or any content displayed through the
                                Services; (b) you shall not modify, make derivative works of, disassemble, reverse
                                compile or reverse engineer any part of the Services; (c) you shall not access the
                                Services in order to build a similar or competitive website, product, or service; (d)
                                except as expressly stated herein, no part of the Services may be copied, reproduced,
                                distributed, republished, downloaded, displayed, posted or transmitted in any form or by
                                any means; (e) you shall remain at all times in compliance with the Acceptable Use
                                Policy set forth below, and (f) you are at least eighteen (18) years of age. You may not
                                assist any third party in carrying out these prohibited activities. Unless otherwise
                                indicated, any future release, update, or other addition to functionality of the
                                Services shall be subject to these Terms. All copyright and other proprietary notices
                                displayed through the Services (or on any content displayed through the Services) must
                                be retained on all copies thereof.
                            </li>
                            <li>
                                <span>Acceptable Use Policy.  </span> The following terms constitute our “Acceptable Use
                                Policy”:
                                <div class="sub_point">
                                    <p>
                                        <span class="alpha">(a) </span> You agree not to use the Services to collect,
                                        upload,
                                        transmit, display, or distribute any User Content or to take any action that:
                                        (i) violates any third-party right, including any copyright, trademark, patent,
                                        trade secret, moral right, privacy right, right of publicity, or any other
                                        intellectual property or proprietary right; (ii) is unlawful, harassing,
                                        abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar,
                                        defamatory, false, intentionally misleading, trade libelous, pornographic,
                                        obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm
                                        of any kind against any group or individual or is otherwise objectionable; (iii)
                                        is harmful to minors in any way; or (iv) is in violation of any law, regulation,
                                        or obligations or restrictions imposed by any third party.
                                    </p>
                                    <p>
                                        <span class="alpha">(b) </span> You agree not to: (i) upload, transmit, or
                                        distribute to or
                                        through the Services any computer viruses, worms, or any software intended to
                                        damage or alter a computer system or data; (ii) send through the Services
                                        unsolicited or unauthorized advertising, promotional materials, junk mail, spam,
                                        chain letters, pyramid schemes, or any other form of duplicative or unsolicited
                                        messages, whether commercial or otherwise; (iii) use the Services to harvest,
                                        collect, gather or assemble information or data regarding other users, including
                                        e-mail addresses, without their consent; (iv) interfere with, disrupt, or create
                                        an undue burden on servers or networks connected to the Services, or attempt to
                                        do so, including via sending a virus, overloading, flooding, spamming,
                                        mail-bombing, or scripting, or violate the regulations, policies or procedures
                                        of the networks over which the Services are available; (v) attempt to gain
                                        unauthorized access to the Services (or to other computer systems or networks
                                        connected to or used together with any Services), whether through password
                                        mining or any other means; (vi) harass or interfere with any other user’s use
                                        and enjoyment of the Services; (vii) use any engine, browser, avatar,
                                        intelligent agent, robot, spider, scraper, deep link, software or automated
                                        agents, tools, algorithms, programs, methodology or scripts to access, acquire,
                                        navigate, search, copy or monitor the Services or any content thereof, to
                                        produce multiple accounts on the Services, or to generate automated searches,
                                        requests, or queries to (or to strip, scrape, or mine data from) the Services
                                        (provided, however, that we conditionally grant to the operators of public
                                        search engines revocable permission to use spiders to copy materials from the
                                        Services for the sole purpose of and solely to the extent necessary for creating
                                        publicly available searchable indices of the materials available on the
                                        Services, but not caches or archives of such materials, subject to any
                                        parameters that we may set forth elsewhere in these Terms or in our robots.txt
                                        files); (vii) access, tamper with, or use non-public areas of the Services,
                                        Company’s computer systems, or the technical delivery systems of Company’s
                                        suppliers, contractors or partners; (viii) probe, scan, or test the
                                        vulnerability of any system or network or breach or circumvent any security or
                                        authentication measures used in connection with the Services; (ix) forge any
                                        TCP/IP packet header or any part of the header information in any email or
                                        posting, or in any way use the Services to send altered, deceptive or false
                                        source-identifying information; (x) commercially exploit any content on the
                                        Services other than content provided by you; (xi) modify, make derivative works
                                        of, disassemble, reverse compile or reverse engineer any part of the Services;
                                        (xii) access the Services in order to build a similar or competitive website,
                                        product, or service; (xiii) use the Services in a way that distracts or prevents
                                        you from obeying traffic or safety laws; (xiv) use the Services for any illegal
                                        activity or goods or in any way that exposes you, other users of the Services,
                                        our supplier, contractors or partners, or Company to harm; or (xv) otherwise use
                                        the Services except as expressly allowed under these Terms.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <span>Modification</span> Company reserves the right, at any time, to modify, suspend,
                                or discontinue any Services (in whole or in part) or the License with or without notice
                                to you, or any Fees (as defined below) charged for the Services. You agree that Company
                                will not be liable to you or to any third party for any modification, suspension, or
                                discontinuation of the Services or the License or the Fees, or any parts thereof.
                            </li>
                            <li>
                                <span>No Support or Maintenance. </span> The provision of any support or maintenance
                                by the Company with respect to the Services shall be in the Company’s sole discretion.
                                You acknowledge and agree that Company will have no obligation to provide you with any
                                support or maintenance in connection with the Services, unless expressly agreed in
                                writing, and may cease providing or refuse to provide support or maintenance services at
                                any time and for any reason.
                            </li>
                            <li>
                                <span>Ownership; Reservation of Rights. </span> You acknowledge and agree that all
                                the Intellectual Property Rights (as defined herein) in the Services and its content are
                                and shall remain owned by Company or Company’s suppliers, contractors or partners. The
                                Company has the right to assign, transfer or sell any such rights or content to a third
                                party, who in turn may have the right to assign, transfer or sell any such rights or
                                content. Neither these Terms (nor your access to the Services) transfers to you or any
                                third party any rights, title or interest in or to such Intellectual Property Rights,
                                except for the limited access rights expressly granted you in the License. Company and
                                its suppliers, contractors and partners reserve all rights not granted in these Terms.
                                <u> There are no implied licenses granted under these Terms. </u>
                            </li>
                            <li>
                                <span>Intellectual Property Rights. </span> For the purposes of these Terms,
                                “Intellectual
                                Property Rights” means all intellectual property rights worldwide arising under
                                statutory or common law, and whether or not perfected, including, without limitation,
                                all (i) patents, patent applications and patent rights; (ii) rights associated with
                                works of authorship including copyrights, copyright applications, and copyright
                                registrations; (iii) rights relating to the protection of trade secrets and confidential
                                information; (iv) any other proprietary rights relating to intangible property (other
                                than trademark, trade dress, or service mark rights); (v) divisions, continuations,
                                renewals, reissues and extensions of the foregoing (as and to the extent applicable) now
                                existing, hereafter filed, issued or acquired; and (vi) all goodwill associated with any
                                of the foregoing.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>CONTENT</span>
                        <ol>
                            <li>
                                <span>Company Content. </span> Except as may be otherwise noted, the information and
                                materials (including, without limitation, HTML, text, audio, video, white papers, press
                                releases, data sheets, product descriptions, source code, object code, software and FAQs
                                and other content) created, generated, published, and/or made available by Company on or
                                via the Services (collectively, “Company Content”) are the copyrighted works of Company
                                and its licensors, and Company and its licensors expressly retain all right title and
                                interest in and to the Company Content, including, without limitation, all Intellectual
                                Property Rights therein and thereto. Except as expressly permitted in these Terms, any
                                use of the Company Content is considered a breach of your contract with the Company
                                under these Terms, and may also violate copyright and/or other applicable laws.
                            </li>
                            <li>
                                <span>Downloadable Content. </span> Downloadable Content. In the event that Company
                                makes any Company
                                Content available to be downloaded and/or printed through the Services, as applicable,
                                Company hereby grants you the limited, revocable, non-transferable, non-exclusive right
                                to download and print such Company Content under the condition that (i) such activity is
                                solely for your personal, education or other noncommercial use, (ii) you do not modify
                                or prepare derivative works from the Company Content, (iii) you do not obscure, alter or
                                remove any notice of copyright set forth on any Services or Company Content, (iv) you do
                                not otherwise reproduce, re-distribute or publicly display any of the Company Content
                                and (v) you do not copy any Company Content to any other media or other storage format.
                            </li>
                            <li>
                                <span>Third-Party Links & Ads. </span> The Services may contain links to third-party
                                websites and services, and/or display advertisements for third parties (collectively,
                                “Third-Party Links & Ads”). Such Third-Party Links & Ads are not under the control of
                                Company, and Company is not responsible for any Third-Party Links & Ads. Company
                                provides access to these Third-Party Links & Ads only as a convenience to you, and does
                                not review, approve, monitor, endorse, warrant, or make any representations with respect
                                to Third-Party Links & Ads. You use all Third-Party Links & Ads at your own risk, and
                                should apply a suitable level of caution and discretion in doing so. When you click on
                                any of the Third-Party Links & Ads, the applicable third party’s terms and policies
                                apply, including the third party’s privacy and data gathering practices. You should make
                                whatever investigation you feel necessary or appropriate before proceeding with any
                                transaction in connection with such Third-Party Links & Ads.
                            </li>
                            <li>
                                <span>User Content. </span>
                                <div class="sub_point">
                                    <p>
                                        <span class="alpha">(a)</span> <span> Definition and Responsibility.  </span>
                                        “User Content” means any and all information and content that a user submits to,
                                        or uses with, the Services (e.g., content in the user’s profile or postings).
                                        You are solely responsible for your User Content. You assume all risks
                                        associated with use by the Company or other parties of your User Content,
                                        including any reliance on its accuracy, completeness or usefulness, or any
                                        disclosure of your User Content that personally identifies you or any third
                                        party. You hereby represent and warrant that your User Content does not violate
                                        our Acceptable Use Policy. You may not represent or imply to others that your
                                        User Content is in any way provided, sponsored or endorsed by Company. Because
                                        you alone are responsible for your User Content, you may expose yourself to
                                        liability if, for example, your User Content violates the Acceptable Use Policy.
                                        Company is not obligated to backup any User Content, and your User Content may
                                        be deleted at any time without prior notice. You are solely responsible for
                                        creating and maintaining your own backup copies of your User Content if you
                                        desire.
                                    </p>
                                    <p>
                                        <span class="alpha">(b)</span> <span> License.  </span> You hereby grant (and
                                        you represent and warrant that you have the right to grant) to Company, an
                                        irrevocable, nonexclusive, perpetual, royalty-free and fully paid, worldwide
                                        license to reproduce, distribute, publicly display and perform, prepare
                                        derivative works of, incorporate into other works, and otherwise use and exploit
                                        your User Content, and to grant sublicenses of the foregoing rights with
                                        multiple levels of sublicensees, for the purposes of including your User Content
                                        in the Services and for any other uses or purposes which Company may have for
                                        such User Content, subject to applicable privacy laws. You hereby irrevocably
                                        waive (and agree to cause to be waived) any claims and assertions of moral
                                        rights or attribution with respect to your User Content.
                                    </p>
                                    <p>
                                        <span class="alpha">(c)</span> <span> Other Users.  </span> Each user of any
                                        Service is solely responsible for any and all of its own User Content. Because
                                        we do not control User Content, you acknowledge and agree that we are not
                                        responsible for any User Content, whether provided by you or by others. We make
                                        no guarantees regarding the accuracy, currency, suitability, or quality of any
                                        User Content. Your interactions with other users of any Services are solely
                                        between you and such users. You agree that Company will not be responsible for
                                        any loss or damage incurred as the result of any such interactions. If there is
                                        a dispute between you and any other user of any Service, we are under no
                                        obligation to become involved.
                                    </p>
                                    <p>
                                        <span class="alpha">(d)</span> <span> Deletion of User Content.  </span> You
                                        understand that any termination of your Account may involve deletion of your
                                        User Content associated with your Account from our live databases. User Content
                                        may also be deleted if you do not access or use your Account for a period of
                                        time longer than 60 days, or due to failure or outages from third-party
                                        applications or services that the Company relies on to store User Content.
                                        Company will not have any liability whatsoever to you for any termination of
                                        your Account or deletion of your User Content.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <span>Feedback. </span> If you provide Company with any feedback or suggestions
                                regarding the Services (“Feedback”), you hereby assign to Company all rights in such
                                Feedback and agree that Company shall have the right to use and fully exploit such
                                Feedback and related information in any manner it deems appropriate. Company will treat
                                any Feedback you provide to Company as non-confidential and non-proprietary. You agree
                                that you will not submit to Company any information or ideas that you consider to be
                                confidential or proprietary.
                            </li>
                            <li>
                                <span>Copyright Policy. </span> As a condition of your right to use the Services, you
                                agree to respect the Intellectual Property Rights of others. Accordingly, you agree not
                                to upload or post to the Service any copyrighted materials, trademarks or other
                                proprietary information belonging to any third party without the prior written consent
                                of the applicable third party. In connection with the Services, we have adopted and
                                implemented a Digital Millennium Copyright Act (“DMCA”) procedure. Company’s DMCA
                                procedure is in accordance with that suggested by DMCA, the text of which can be found
                                at the U.S. Copyright Office web site (as of the last revision date of these Terms,
                                located at http://www.copyright.gov/legislation/dmca.pdf). If you believe that your
                                copyrighted work has been illegally uploaded or posted on the Services, you may send a
                                written notice to Company at the email set forth in the Company Contact Information
                                above, and Company will respond pursuant to its DMCA procedure. Company reserves all
                                rights to seek damages and fees associated with infringement and/or fraud.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>PAYMENT TERMS.  </span>
                        <ol>
                            <li>
                                <span>Company Paid Services. </span> Company may offer Services to be paid for on a
                                recurring basis (“Subscription Services”) or on an as-used basis (“A La Carte Services”
                                and, together with the Subscription Services, “Paid Services”). The License to use and
                                access any Paid Services is expressly conditioned on full payment of any amounts payable
                                therefor (the “Fees”). By using or accessing any Paid Services, you hereby agree to pay
                                in full any applicable Fees for such Paid Services which may be established by Company
                                from time to time. Failure to comply with or otherwise pay Fees due is a material breach
                                of these Terms.
                            </li>
                            <li>
                                <span>Subscription. </span> You agree to pay the subscription charges for the full
                                length and price of your contract.
                            </li>
                            <li>
                                <span>Onboarding Fee. </span> You agree to pay an onboarding fee at the start of
                                service.
                            </li>
                            <li>
                                <span>Minimum Ad Budget. </span> You agree to pay the minimum ad budget as determined
                                by the Company. If you would like to discontinue the ad budget, you could do so by
                                contacting support. By discontinuing the ad budget, you will still have to pay monthly
                                subscription charges for the remainder of your contract. Discontinuing the ad budget
                                will result in zero leads.
                            </li>
                            <li>
                                <span>Fee Amounts; Changes. </span> Fees may be established and communicated by the
                                Company via email, a posting through the Services (such as on the Site or in the App), a
                                notice posted inside or delivered to your Account, or as otherwise agreed by written
                                agreement or order form executed by you and the Company. Company has the right to
                                change, delete, discontinue or impose conditions on Paid Services or any feature or
                                aspect of a Paid Service at any time, including the Fees associated with such Paid
                                Services. Notice of any Fee changes may be delivered via email, a posting through the
                                Services (such as on the Site or in the Platform), or a notice posted inside or
                                delivered to your Account. You agree to periodically review Company pricing and policies
                                made available through the Services in order to stay informed. Your continued use of any
                                Paid Services affected by a change in Fees will constitute your agreement to such
                                changes and authorization for the Company to collect any such Fees on a going forward
                                basis. Any change to Fees and other charges will not be applicable until the billing
                                period or renewal period after the period in which the change to Fees occurs.
                            </li>
                            <li>
                                <span>Non-Refundable; Taxes. </span> All Fees (including professional services such
                                as installation and training) are non-refundable. All Fees are exclusive of any and all
                                taxes, levies or duties imposed by taxing authorities, and you acknowledge and agree
                                that you are responsible for all taxes applicable to the Fees, Paid Services or any
                                related charges in any applicable jurisdictions.
                            </li>
                            <li>
                                <span>Payment. </span> Fees may be paid by debit card, credit card, or other form
                                that Company communicates in writing as being acceptable. If you link a debit or credit
                                card to your account, you hereby authorize us to collect all Fees by debit from your
                                linked debit card or charge to your linked credit card without further authorization,
                                including any charges or fees incurred as a result of such debit or charge. Regardless
                                of payment method, we reserve the right to collect Fees from any balance in your Account
                                or from your linked bank account.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>INDEMNIFICATION. </span> You agree to indemnify and hold Company (and its officers,
                        employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand
                        made by any third party due to or arising out of (a) your use of the Services, (b) your
                        violation of these Terms, (c) your violation of applicable laws or regulations or (d) your User
                        Content and the access, reliance on or use of your User Content by any other users of the
                        Services. Company reserves the right, at your expense, to assume the exclusive defense and
                        control of any matter for which you are required to indemnify us, and you agree to cooperate
                        with our defense of these claims. You agree not to settle any matter without the prior written
                        consent of Company. Company will use reasonable efforts to notify you of any such claim, action
                        or proceeding upon becoming aware of it.
                    </li>
                    <li>
                        <span>LIMITATION ON LIABILITY; DISCLAIMERS; RELEASE.</span>
                        <ol>
                            <li>
                                <span>Release.  </span> You hereby release and forever discharge the Company (and our
                                officers, employees, agents, contractors, partners, successors, and assigns) from, and
                                hereby waive and relinquish, each and every past, present and future dispute, claim,
                                controversy, demand, right, obligation, liability, action and cause of action of every
                                kind and nature (including personal injuries, death, and property damage), that has
                                arisen or arises directly or indirectly out of, or that relates directly or indirectly
                                to, the Services (including any interactions with, or act or omission of, other users of
                                any Services, any User Content, or any Third-Party Links & Ads), including any disputes,
                                claims, controversies, demands, rights, obligations, liabilities, actions or causes of
                                actions that may not yet exist or facts of which may not be presently known to you. IF
                                YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN
                                CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO
                                CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
                                TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
                                AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
                            </li>
                            <li>
                                <span>Disclaimers. </span>
                                <div class="sub_point">
                                    <p>
                                        <span class="alpha">(a)</span> THE SERVICES ARE PROVIDED ON AN “AS-IS” AND
                                        “AS AVAILABLE” BASIS, AND COMPANY (AND OUR SUPPLIERS, CONTRACTORS AND PARTNERS)
                                        EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
                                        EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF
                                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT,
                                        ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE
                                        SERVICES WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED,
                                        TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF
                                        VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW
                                        REQUIRES ANY WARRANTIES WITH RESPECT TO THE SERVICES, ALL SUCH WARRANTIES ARE
                                        LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.
                                    </p>
                                    <p>
                                        <span class="alpha">(b)</span> SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
                                        OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME
                                        JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO
                                        THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <span>Limitation on Liability.</span>
                                <div class="sub_point">
                                    <p>
                                        <span class="alpha">(a)</span> TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO
                                        EVENT SHALL COMPANY (OR OUR SUPPLIERS, CONTRACTORS OR PARTNERS) BE LIABLE TO YOU
                                        OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF
                                        SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
                                        SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE
                                        OF, OR INABILITY TO USE, THE SERVICES, EVEN IF COMPANY HAS BEEN ADVISED OF THE
                                        POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN
                                        DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                                        DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
                                    </p>
                                    <p>
                                        <span class="alpha">(b)</span> TO THE MAXIMUM EXTENT PERMITTED BY LAW,
                                        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU
                                        FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE
                                        WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE
                                        LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN
                                        ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS, CONTRACTORS
                                        AND PARTNERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
                                        AGREEMENT.
                                    </p>
                                    <p>
                                        <span class="alpha">(c)</span> SOME JURISDICTIONS DO NOT ALLOW THE
                                        LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
                                        THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <span>Acknowledgement. </span> You acknowledge that the Services may not function
                                correctly and without error all the time, and agree that Company shall not be held
                                liable whatsoever for any downtime in accessibility or errors in its functionality.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>TERM AND TERMINATION. </span> Subject to this Section, these Terms will remain in full
                        force and effect while you use any Services. We may suspend or terminate your License to use the
                        Services (including your Account) at any time for any reason at our sole discretion. If you use
                        or attempt to use any of the Services in violation or breach of these Terms, your License to use
                        the Services shall automatically and immediately terminate. Upon termination of your License
                        under these Terms, your Account and right to access and use the Services will terminate
                        immediately. Company will not have any liability whatsoever to you for any termination of your
                        rights, Account or License under these Terms. Even after your rights under these Terms are
                        terminated, the following provisions of these Terms will remain in effect: Sections 2.2 through
                        Section 10, and Section 11.5. Upon termination of your License under these Terms, you hereby
                        agree to immediately uninstall, remove, delete, destroy and cease using (as applicable), any and
                        all of the Services, and agree not to use the Services in the future without express consent
                        from Company.
                    </li>
                    <li>
                        <span>DISPUTE RESOLUTION. </span> Please read the provisions in this section (collectively,
                        the “Arbitration Clause”) carefully as they are an agreement that relates to dispute resolution
                        via arbitration. It is part of your contract with Company and affects your rights. It contains
                        procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.
                        <ol>
                            <li>
                                <span>Applicability of Arbitration Clause. </span> All claims and disputes (excluding
                                claims for injunctive or other equitable relief as set forth below) in connection with
                                the Terms or the use of any product or service provided by the Company that cannot be
                                resolved informally or in small claims court shall be resolved by binding arbitration on
                                an individual basis under the terms of this Arbitration Clause. Unless otherwise agreed
                                to, all arbitration proceedings shall be held in English. This Arbitration Clause
                                applies to you and the Company, and to any subsidiaries, affiliates, agents, employees,
                                predecessors in interest, successors, and assigns, as well as all authorized or
                                unauthorized users or beneficiaries of services or goods provided under the Terms.
                            </li>
                            <li>
                                <span>Notice Requirement and Informal Dispute Resolution. </span> Before either party
                                may seek arbitration, the party must first send to the other party a written Notice of
                                Dispute (“Notice”) describing the nature and basis of the claim or dispute, and the
                                requested relief. A Notice to the Company should be sent to the Company address set
                                forth above. After the Notice is received, you and the Company may attempt to resolve
                                the claim or dispute informally. If you and the Company do not resolve the claim or
                                dispute within thirty (30) days after the Notice is received, either party may begin an
                                arbitration proceeding. The amount of any settlement offer made by any party may not be
                                disclosed to the arbitrator until after the arbitrator has determined the amount of the
                                award, if any, to which either party is entitled.
                            </li>
                            <li>
                                <span>Arbitration Rules. </span> Arbitration shall be initiated through the American
                                Arbitration Association (“AAA”), an established alternative dispute resolution provider
                                (“ADR Provider”) that offers arbitration as set forth in this section. If AAA is not
                                available to arbitrate, the parties shall agree to select an alternative ADR Provider.
                                The rules of the ADR Provider shall govern all aspects of the arbitration, including but
                                not limited to the method of initiating and/or demanding arbitration, except to the
                                extent such rules are in conflict with the Terms. The AAA Consumer Arbitration Rules
                                (“Arbitration Rules”) governing the arbitration are available online at www.adr.org or
                                by calling the AAA at 1-800-778-7879. The arbitration shall be conducted by a single,
                                neutral arbitrator. Any claims or disputes where the total amount of the award sought is
                                less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding
                                non-appearance-based arbitration, at the option of the party seeking relief. For claims
                                or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US
                                $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules.
                                Any hearing will be held in a location within 100 miles of your residence, unless you
                                reside outside of the United States, and unless the parties agree otherwise. If you
                                reside outside of the U.S., the arbitrator shall give the parties reasonable notice of
                                the date, time and place of any oral hearings. Any judgment on the award rendered by the
                                arbitrator may be entered in any court of competent jurisdiction. If the arbitrator
                                grants you an award that is greater than the last settlement offer that the Company made
                                to you prior to the initiation of arbitration, the Company will pay you the greater of
                                the award or $2,500.00. Each party shall bear its own costs (including attorney’s fees)
                                and disbursements arising out of the arbitration and shall pay an equal share of the
                                fees and costs of the ADR Provider.
                            </li>
                            <li>
                                <span>Additional Rules for Non-Appearance Based Arbitration. </span> If
                                non-appearance based arbitration is elected, the arbitration shall be conducted by
                                telephone, online and/or based solely on written submissions; the specific manner shall
                                be chosen by the party initiating the arbitration. The arbitration shall not involve any
                                personal appearance by the parties or witnesses unless otherwise agreed by the parties.
                            </li>
                            <li>
                                <span>Time Limits. </span> If you or the Company pursue arbitration, the arbitration
                                action must be initiated and/or demanded within the statute of limitations (i.e., the
                                legal deadline for filing a claim) and within any deadline imposed under the AAA Rules
                                for the pertinent claim.
                            </li>
                            <li>
                                <span>Authority of Arbitrator. </span> If arbitration is initiated, the arbitrator
                                will decide the rights and liabilities, if any, of you and the Company, and the dispute
                                will not be consolidated with any other matters or joined with any other cases or
                                parties. The arbitrator shall have the authority to grant motions dispositive of all or
                                part of any claim. The arbitrator shall have the authority to award monetary damages,
                                and to grant any non-monetary remedy or relief available to an individual under
                                applicable law, the AAA Rules, and the Terms. The arbitrator shall issue a written award
                                and statement of decision describing the essential findings and conclusions on which the
                                award is based, including the calculation of any damages awarded. The arbitrator has the
                                same authority to award relief on an individual basis that a judge in a court of law
                                would have. The award of the arbitrator is final and binding upon you and the Company.
                            </li>
                            <li>
                                <span>Waiver of Jury Trial. </span> Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR
                                CONSTITUTIONAL AND
                                STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead
                                electing that all claims and disputes shall be resolved by arbitration under this
                                Arbitration Clause. Arbitration procedures are typically more limited, more efficient
                                and less costly than rules applicable in a court and are subject to very limited review
                                by a court. In the event any litigation should arise between you and the Company in any
                                state or federal court in a suit to vacate or enforce an arbitration award or otherwise,
                                YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute
                                be resolved by a judge.
                            </li>
                            <li>
                                <span>Waiver of Class or Consolidated Actions. </span> ALL CLAIMS AND DISPUTES WITHIN
                                THE SCOPE OF THIS ARBITRATION CLAUSE MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL
                                BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
                                ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR
                                USER.
                            </li>
                            <li>
                                <span>Confidentiality. </span> All aspects of the arbitration proceeding, including
                                but not limited to the award of the arbitrator and compliance therewith, shall be
                                strictly confidential. The parties agree to maintain confidentiality unless otherwise
                                required by law. This paragraph shall not prevent a party from submitting to a court of
                                law any information necessary to enforce this Agreement, to enforce an arbitration
                                award, or to seek injunctive or equitable relief.
                            </li>
                            <li>
                                <span>Severability. </span> If any part or parts of this Arbitration Clause are
                                found under the law to be invalid or unenforceable by a court of competent jurisdiction,
                                then such specific part or parts shall be of no force and effect and shall be severed
                                and the remainder of the Agreement shall continue in full force and effect.
                            </li>
                            <li>
                                <span>Right to Waive. </span> Any or all of the rights and limitations set forth in
                                this Arbitration Clause may be waived by the party against whom the claim is asserted.
                                Such waiver shall not waive or affect any other portion of this Arbitration Agreement.
                            </li>
                            <li>
                                <span>Survival of Agreement. </span> This Arbitration Clause will survive the
                                termination of your relationship with Company.
                            </li>
                            <li>
                                <span>Small Claims Court.</span> Notwithstanding the foregoing, either you or the
                                Company may bring an individual action in small claims court.
                            </li>
                            <li>
                                <span>Emergency Equitable Relief. </span> Notwithstanding the foregoing, either
                                party may seek emergency equitable relief before a state or federal court in order to
                                maintain the status quo pending arbitration. A request for interim measures shall not be
                                deemed a waiver of any other rights or obligations under this Arbitration Clause.
                            </li>
                            <li>
                                <span>Claims Not Subject to Arbitration. </span> Notwithstanding the foregoing,
                                claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement or
                                misappropriation of the other party’s patent, copyright, trademark or trade secrets
                                shall not be subject to this Arbitration Clause.
                            </li>
                            <li>
                                <span>Courts. </span> In any circumstances where the foregoing Arbitration Clause
                                permits the parties to litigate in court, the parties hereby agree to submit to the
                                personal jurisdiction of the courts located within Utah County, Utah, for such purpose
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>GENERAL</span>
                        <ol>
                            <li>
                                <span>Changes.</span> These Terms are subject to occasional revision, and if we make
                                any substantial changes, we may notify you by sending you an e-mail to the last e-mail
                                address you provided to us (if any), and/or by prominently posting notice of the changes
                                on the Site or through your Account. You are responsible for providing us with your most
                                current e-mail address. In the event that the last e-mail address that you have provided
                                us is not valid, or for any reason is not capable of delivering to you the notice
                                described above, our dispatch of the e-mail containing such notice will nonetheless
                                constitute effective notice of the changes described in the notice. Any changes to these
                                Terms will be effective upon the earlier of thirty (30) calendar days following our
                                dispatch of an e-mail notice to you (if applicable) or thirty (30) calendar days
                                following our posting of notice of the changes on our Site. These changes will be
                                effective immediately for new users of the Services. Continued use of the Services
                                following notice of such changes shall indicate your acknowledgement of such changes and
                                agreement to be bound by the terms and conditions of such changes.
                            </li>
                            <li>
                                <span>Export.</span> The Services may be subject to U.S. export control laws and may
                                be subject to export or import regulations in other countries. You agree not to export,
                                reexport, or transfer, directly or indirectly, any U.S. technical data acquired from
                                Company, or any products utilizing such data, in violation of the United States export
                                laws or regulations.
                            </li>
                            <li>
                                <span>Disclosures. </span> Company is located at the address set forth above. If you
                                are a California resident, you may report complaints to the Complaint Assistance Unit of
                                the Division of Consumer Product of the California Department of Consumer Affairs by
                                contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at
                                (800) 952-5210.
                            </li>
                            <li>
                                <span>Electronic Communications. </span> The communications between you and Company
                                use electronic means, whether you use the Services or send us emails, or whether Company
                                posts notices via the Services or communicates with you via email. For contractual
                                purposes, you (a) consent to receive communications from Company in an electronic form;
                                and (b) agree that all terms and conditions, agreements, notices, disclosures, and other
                                communications that Company provides to you electronically satisfy any legal requirement
                                that such communications would satisfy if it were be in a hardcopy writing. The
                                foregoing does not affect your non-waivable rights.
                            </li>
                            <li>
                                <span>Rezi Leads Does Not Guarantee Leads. </span> You understand that Rezi Leads
                                does not guarantee any number of leads. You understand that Rezi Leads does not
                                guarantee that any of the leads provided will result in buyer or seller transactions.
                            </li>
                            <li>
                                <span>Entire Terms; Construction; Severability; Assignment. </span> These Terms
                                constitute the entire agreement between you and us regarding the use of the Services.
                                Our failure to exercise or enforce any right or provision of these Terms shall not
                                operate as a waiver of such right or provision. The section titles in these Terms are
                                for convenience only and have no legal or contractual effect. The word “including” means
                                “including without limitation”. If any provision of these Terms is, for any reason, held
                                to be invalid or unenforceable, the other provisions of these Terms will be unimpaired
                                and the invalid or unenforceable provision will be deemed modified so that it is valid
                                and enforceable to the maximum extent permitted by law. Your relationship to Company is
                                that of an independent contractor, and neither party is an agent or partner of the
                                other. These Terms, and your rights and obligations herein, may not be assigned,
                                subcontracted, delegated, or otherwise transferred by you without Company’s prior
                                written consent, and any attempted assignment, subcontract, delegation, or transfer in
                                violation of the foregoing will be null and void. Company may freely assign these Terms.
                                The terms and conditions set forth in these Terms shall be binding upon assignees.
                            </li>
                            <li>
                                <span>Copyright/Trademark Information. </span> Copyright © 2022 REZI LEADS INC.. All
                                rights reserved. All trademarks, logos and service marks (“Marks”) displayed in
                                connection with any Services are our property or the property of other third parties.
                                You are not permitted to use these Marks without our prior written consent or the
                                consent of such third party which may own the Marks.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>

        </div>

        <Footer/>
    </div>
</template>

<script>

    import NavBar from "../components/NavBar";
    import Footer from "../components/Footer";

    export default {
        name: "Tos",
        data() {
            return {}
        },
        components: {
            NavBar,
            Footer
        }
    }
</script>

<style scoped lang="scss">
    .main {
        &_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .tos {
        &_content {
            width: 100%;
            padding: 100px 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1640px;
            margin: 0 auto;
        }

        &_title {
            padding: 30px 0;
            font-size: 36px;
            font-weight: 600;
            color: $darkBlue;
        }

        &_sub-content {
            padding: 15px 0;
        }

        &_sub-item {
            padding: 10px 0;
            font-size: 24px;

            & span {
                color: $main_blue;
                font-weight: 600;
                margin-right: 10px;
            }
        }

        &_points {
            padding: 10px 10px 40px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;

            ol {
                list-style-type: none;
                counter-reset: item;
                margin: 0;
                padding: 0;
            }

            & li {
                padding: 10px 0;
                display: table;
                counter-increment: item;
                margin-bottom: 0.6em;

                & ul {
                    margin-left: 15px;

                    & li {
                        list-style-type: circle;
                    }
                }

                & li {
                    margin: 0;

                    &:before {
                        content: counters(item, ".") " ";
                    }
                }

                &:before {
                    content: counters(item, ".") ". ";
                    display: table-cell;
                    padding-right: 0.6em;
                    color: $light_blue;
                    font-weight: 600;
                }

                & span {
                    color: $light_blue;
                    font-weight: 600;
                }
            }

            ::v-deep .sub_point {
                & p {
                    margin: 8px 0;

                    & .alpha {
                        margin-left: 25px;
                    }
                }

                li {
                    margin-left: 15px;
                }
            }
        }
    }
</style>